// src/AppLayout.js

import React, { useState } from 'react';
import { X, LogIn, LogOut } from 'lucide-react';
import { MENU_ITEMS } from './config/dashboardConfig';
import LoginComponent from './LoginComponent';

const AppLayout = ({ children, onLoginSuccess, user, onLogout, selectedItem, setSelectedItem }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const toggleSidebar = () => setIsOpen(!isOpen);
  const openLoginModal = () => setShowLoginModal(true);
  const closeLoginModal = () => setShowLoginModal(false);

  const handleLoginSuccess = (userData) => {
    onLoginSuccess(userData);
    closeLoginModal();
  };

  // Create a new child element with the selectedItem prop
  const childrenWithProps = React.Children.map(children, child => {
    // Checking if the child is a valid element before cloning
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { selectedItem });
    }
    return child;
  });

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div className={`bg-gray-800 text-white w-45 space-y-6 py-7 px-2 flex flex-col h-full absolute inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0 transition duration-200 ease-in-out`}>
        <button onClick={toggleSidebar} className="md:hidden absolute right-2 top-2 text-gray-300 hover:text-white">
          <X size={24} />
        </button>
        <nav className='flex-1'>
          {MENU_ITEMS.map((item) => (
            <button
              key={item.name}
              className={`w-full text-left block py-2.5 px-4 rounded transition duration-200 ${selectedItem === item.name ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
              onClick={() => {
                console.log("Sidebar button clicked:", item.name);
                setSelectedItem(item.name);}}
            >
              <item.icon className="inline-block mr-2" size={20} />
              {item.name}
            </button>
          ))}
        </nav>
        <div className="px-2 mt-4 space-y-2">
        {!user && (
          <button 
            onClick={openLoginModal}
            className="w-full py-2 block px-4 bg-gray-800 hover:bg-gray-700 text-white text-left rounded transition duration-200"
          >
            <LogIn className="mr-2 inline-block" size={20} />
            Login
          </button>
        )}
        {user && (
          <button 
            onClick={onLogout}
            className="w-full py-2.5 block px-4 bg-gray-800 hover:bg-gray-700 text-white text-left rounded transition duration-200"
          >
            <LogOut className="mr-2 inline-block" size={20} />
            Logout
          </button>
        )}
      </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Top Navbar */}
        

        {/* Page Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-background">
          {childrenWithProps}
        </main>
      </div>
      {showLoginModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 rounded-lg shadow-xl backdrop-blur-sm">
          <div className="bg-white rounded-lg shadow-xl relative">
            <button 
                onClick={closeLoginModal} 
                className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
              >
                <X size={24} />
            </button>
            <LoginComponent onLoginSuccess={handleLoginSuccess}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppLayout;