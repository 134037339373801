// ShoppingCartContext.js
import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
  cart: [], // Array to store cart items
};

const ShoppingCartContext = createContext();

function cartReducer(state, action) {
  switch (action.type) {
    case 'ADD_TO_CART':
      // Check if the product is already in the cart
      const existingItem = state.cart.find((item) => item.id === action.payload.id);
      if (existingItem) {
        // If it exists, update the quantity
        return {
          ...state,
          cart: state.cart.map((item) =>
            item.id === action.payload.id
              ? { ...item, quantity: item.quantity + 1 }
              : item
          ),
        };
      } else {
        // If it doesn't exist, add it to the cart
        return { ...state, cart: [...state.cart, { ...action.payload, quantity: 1 }] };
      }

    case 'REMOVE_FROM_CART':
      // Remove the item from the cart
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.payload.id),
      };

    default:
      return state;

    case 'CLEAR_CART':
      return {
        ...state,
        cart: []
      }
  }
}

export function ShoppingCartProvider({ children }) {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  return (
    <ShoppingCartContext.Provider value={{ state, dispatch }}>
      {children}
    </ShoppingCartContext.Provider>
  );
}

export function useShoppingCart() {
  return useContext(ShoppingCartContext);
}
