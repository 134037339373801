import { 
  BarChart2,
  ChartColumnStacked,
  Activity,
  Radar,
  Waves,
  Tv,
  Moon,
  ShipIcon,
  User
} from 'lucide-react';

export const ENDPOINTS = {
    'Cycle': {
      'QQQ 30min': {
        url: process.env.REACT_APP_API_ENDPOINT_QQQ_30MIN,
        title: 'QQQ 30分钟周期'
      },
      'SPY 30min': {
        url: process.env.REACT_APP_API_ENDPOINT_SPY_30MIN,
        title: 'SPY 30分钟周期'
      },
      'QQQ Daily': {
        url: process.env.REACT_APP_API_ENDPOINT_QQQ_DAILY,
        title: 'QQQ 日线周期'
      },
      'SPY Daily': {
        url: process.env.REACT_APP_API_ENDPOINT_SPY_DAILY,
        title: 'SPY 日线周期'
      },
    },
    'Gamma Profile': {
      'SPX': {
        url: `${process.env.REACT_APP_API_BASE_URL}/spx_gamma`,
        title: 'SPX Gamma Profile',
        symbol: 'SPX'
      },
      'QQQ': {
        url: `${process.env.REACT_APP_API_BASE_URL}/ndx_gamma`,
        title: 'QQQ Gamma Profile',
        symbol: 'NDX'
      },
      'RUT': {
        url: `${process.env.REACT_APP_API_BASE_URL}/rut_gamma`,
        title: 'RUT Gamma Profile',
        symbol: 'RUT'
      }
    },
    'Expected Move': {
      'QQQ': {
        url: process.env.REACT_APP_API_ENDPOINT_QQQ_EXPECTED_MOVE,
        title: 'QQQ Expected Move',
        symbol: 'QQQ'
      },
      'SPY': {
        url: process.env.REACT_APP_API_ENDPOINT_SPY_EXPECTED_MOVE,
        title: 'SPY Expected Move',
        symbol: 'SPY'
      },
    },
    'Liquidity': {
      'Market Liquidity': {
        url: `${process.env.REACT_APP_API_BASE_URL}/api/get-fed-liq`,
        title: 'Market Liquidity'
      }
    },
    'Scanner': {
      'Stock Scanner': {
        url: `${process.env.REACT_APP_API_BASE_URL}/stocks/scanner`,
        title: 'Stock Scanner'
      }
    },
    'Dark Pool':{
      'Dark Pool Volumes': {
        url: `${process.env.REACT_APP_API_BASE_URL}/stocks/regsho`,
        title: 'Dark Pool'
      }
    },
    'Live Room': {
      'Trading Room': {
        url: process.env.REACT_APP_LIVE_ROOM_URL,
        title: 'Live Trading Room'
      }
    },
    'Shop': {
      'Courses': {
        url: `${process.env.REACT_APP_API_BASE_URL}/shop/courses`,
        title: 'Shop Courses'
      }
    },
    'Account':{
      'Details':{
        url: `${process.env.REACT_APP_API_BASE_URL}/auth/account-details`,
        title: 'Account Details'
      }
    }
};

export const MENU_ITEMS = [
  { name: 'Cycle', icon: BarChart2 },
  { name: 'Gamma Profile', icon: ChartColumnStacked },
  { name: 'Expected Move', icon: Activity },
  { name: 'Scanner', icon: Radar },
  { name: 'Liquidity', icon: Waves },
  { name: 'Live Room', icon: Tv },
  { name: 'Dark Pool', icon: Moon },
  { name: 'Shop', icon: ShipIcon },
  { name: 'Account', icon: User }
];