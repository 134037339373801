import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppLayout from './AppLayout';
import Dashboard from './Dashboard';
import PasswordResetComponent from './PasswordResetComponent';
import TestResetToken from './TestResetToken';
import { ShoppingCartProvider } from './ShoppingCartContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [user, setUser] = useState(null);
  const [selectedItem, setSelectedItem] = useState('Cycle');

  const handleLoginSuccess = (userData) => {
    setUser(userData);
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
      <ShoppingCartProvider>
        <BrowserRouter>
          <AppLayout
            onLogout={handleLogout}
            user={user}
            onLoginSuccess={handleLoginSuccess}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          >
            <Routes>
              <Route path="/test/reset-token" element={<TestResetToken />} />

              {/* Dashboard Route - NOW INSIDE ROUTES and with a path! */}

              {/* Password Reset Route */}
              <Route
                path="/auth/reset-password/:resetToken"
                element={<PasswordResetComponent />}
              />
              <Route path="/" element={<Dashboard selectedItem={selectedItem} />} /> {/*  <-- Define a route for Dashboard (path="/" or "/dashboard") */}

            </Routes>
          </AppLayout>
        </BrowserRouter>
      </ShoppingCartProvider>
    </>
  );
}

export default App;