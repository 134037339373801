import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useShoppingCart } from './ShoppingCartContext';
import {
    Elements,
    CardElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            fontSize: '16px',
            color: '#32325d',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    },
    hidePostalCode: true // Since you're using USD, you might want to collect this separately or hide it
};

const PaymentForm = ({ customer, items, onClose, onSuccess, onError }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements || !cardComplete) {
            return;
        }

        setLoading(true);
        setError(null);

        const orderData = {
            customer,
            items,
        };

        try {
            // First, create PaymentIntent on your server
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/shop/create-payment-intent`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(orderData),
            });

            const { clientSecret, error: serverError } = await response.json();

            if (serverError) {
                setError(serverError);
                setLoading(false);
                return;
            }

            if (!clientSecret) {
                setError('Failed to create payment intent');
                setLoading(false);
                return;
            }

            // Then confirm the payment with the card element
            const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
                clientSecret,
                {
                    payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            name: `${customer.firstName} ${customer.lastName}`,
                            email: customer.email,
                        },
                    }
                }
            );

            if (stripeError) {
                setError(stripeError.message);
                setLoading(false);
                onError();
                return;
            }

            if (paymentIntent.status === 'succeeded') {
                onSuccess();
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleCardChange = (event) => {
        setError(event.error ? event.error.message : '');
        setCardComplete(event.complete);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    信用卡信息
                </label>
                <div className="border rounded-md p-4 bg-white">
                    <CardElement
                        options={CARD_ELEMENT_OPTIONS}
                        onChange={handleCardChange}
                    />
                </div>
            </div>

            {error && (
                <div className="text-red-500 text-sm mb-4">
                    {error}
                </div>
            )}

            <div className="flex justify-end space-x-2">
                <button
                    type="button"
                    onClick={onClose}
                    className="bg-zinc-200 px-4 py-2 rounded hover:bg-zinc-300"
                    disabled={loading}
                >
                    取消
                </button>
                <button
                    type="submit"
                    disabled={!stripe || !cardComplete || loading}
                    className={`bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600 disabled:opacity-50 ${loading ? 'cursor-not-allowed' : ''}`}
                >
                    {loading ? '处理中...' : '确认支付'}
                </button>
            </div>
        </form>
    );
};

// Main CustomerInfoForm component
const CustomerInfoForm = ({ onClose, cartItems, subtotal }) => {
    const [customer, setCustomer] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });
    const [showPayment, setShowPayment] = useState(false);
    const { dispatch } = useShoppingCart();


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomer({ ...customer, [name]: value });
    };

    const handleCustomerSubmit = (e) => {
        e.preventDefault();
        setShowPayment(true);
    };

    // Function to clear the cart
    const clearCart = () => {
        // Dispatch the action to clear the cart using ShoppingCartContext
        dispatch({ type: 'CLEAR_CART' }); // This will effectively clear the cart
    };

    const handlePaymentSuccess = () => {
        // Handle successful payment (e.g., show success message, redirect)
        console.log('Payment successful!');

        toast.success("支付成功！感谢您的购买！", {
            position: 'top-right',
        });
        clearCart();
        onClose();
    };
    const handlePaymentError = (errorMessage) => {
        toast.error(`支付失败: ${errorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
    };

    // Transform cartItems to only include id and quantity
    const items = cartItems.map(item => ({
        id: item.id,
        quantity: item.quantity
    }));

    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg w-96">
                <h2 className="text-lg font-semibold mb-4">顾客信息</h2>
                {!showPayment ? (
                    <>
                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm">名字</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={customer.firstName}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border border-gray-300 rounded"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm">姓氏</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={customer.lastName}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border border-gray-300 rounded"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm">Email地址</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={customer.email}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border border-gray-300 rounded"
                                    required
                                />
                            </div>
                        </div>
                        <div className="mb-4 border-b pb-2 mt-4">
                            <div className="space-y-2">
                                <h3 className="text-lg font-medium">订单详情</h3>
                                <ul className="text-sm text-gray-700">
                                    {cartItems.map((item) => (
                                        <li key={item.id} className="flex justify-between">
                                            <span>{item.title} x{item.quantity}</span>
                                            <span>${(item.price / 100).toFixed(2)}</span>
                                        </li>
                                    ))}
                                </ul>
                                <div className="flex justify-between font-semibold mt-2">
                                    <span>总计:</span>
                                    <span>${(subtotal / 100).toFixed(2)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={onClose}
                                className="bg-zinc-200 px-4 py-2 rounded hover:bg-zinc-300"
                            >
                                取消
                            </button>
                            <button
                                onClick={handleCustomerSubmit}
                                className="bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600"
                            >
                                继续
                            </button>
                        </div>
                    </>
                ) : (
                    <Elements stripe={stripePromise}>
                        <PaymentForm
                            customer={customer}
                            items={items}
                            onClose={onClose}
                            onSuccess={handlePaymentSuccess}
                            onError={handlePaymentError}
                        />
                    </Elements>
                )}
            </div>
        </div>
    );
};

export default CustomerInfoForm;