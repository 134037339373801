import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import axios from 'axios';

const ExpectedMoveComponent = ({ apiEndpoint, chartTitle, symbol }) => {
  const chartRef = useRef(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiEndpoint}?symbol=${symbol}`);
        setData(response.data);
      } catch (error) {
        console.error(`Error fetching ${symbol} Expected Move data:`, error);
        setError('Failed to fetch data. Please try again later.');
      }
    };

    fetchData();
  }, [apiEndpoint, symbol]);

  useEffect(() => {
    if (!chartRef.current || !data) return;

    const chart = echarts.init(chartRef.current);

    const option = {
      title: { 
        text: chartTitle,
        left: 'center',
        textStyle: {
          color: "#eeeeee",
        }, 
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      backgroundColor: "rgba(51,51,51,1)",
      textStyle: {
        color: "#eeeeee",
      },
      color: [
        "#dd6b66", "#759aa0", "#e69d87", "#8dc1a9", "#ea7e53",
        "#eedd78", "#73a373", "#73b9bc", "#7289ab", "#91ca8c", "#f49f42",
      ],
      
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: "none",
          },
          restore: {},
          saveAsImage: {},
        },
      },
      legend: {
        data: ['Candlestick', 'Expected Upper', 'Expected Lower', 'Expected 2x Upper', 'Expected 2x Lower', 'Expected 3x Upper', 'Expected 3x Lower'],
        top: '30px',
        textStyle: {
          color: "#eeeeee",
        },
      },
      xAxis: { 
        type: 'category', 
        data: data.dates,
        boundaryGap: false,
        axisLine: { onZero: false },
        min: 'dataMin',
        max: 'dataMax' 
      },
      yAxis: { 
        type: 'value',
        scale: true,
        min: 'dataMin',
        max: 'dataMax'
      },
      dataZoom: [
        {
          type: 'inside',
          start: 70,
          end: 100
        },
        {
          show: true,
          type: 'slider',
          top: '90%',
          start: 50,
          end: 100
        }
      ],
      series: [
        {
          name: 'Candlestick',
          type: 'candlestick',
          data: data.candlestick,
          itemStyle: {
            color: '#407899',
            color0: '#de6449',
            borderColor: '#407899',
            borderColor0: '#de6449',
            borderColorDoji: '#C6AD94'
          }
        },
        {
          name: 'Expected Lower',
          type: 'line',
          data: data.expectedLow
        },
        {
          name: 'Expected Upper',
          type: 'line',
          data: data.expectedHigh
        },
        {
          name: 'Expected 2x Upper',
          type: 'line',
          data: data.expectedHigh2x,
          lineStyle: { opacity: 0.5 },
          itemStyle: { color: '#92b6b1' }
        },
        {
          name: 'Expected 2x Lower',
          type: 'line',
          data: data.expectedLow2x,
          lineStyle: { opacity: 0.5 },
          itemStyle: { color: '#92b6b1' }
        },
        {
          name: 'Expected 3x Upper',
          type: 'line',
          data: data.expectedHigh3x,
          lineStyle: { opacity: 0.5 },
          itemStyle: { color: '#e55381' }
        },
        {
          name: 'Expected 3x Lower',
          type: 'line',
          data: data.expectedLow3x,
          lineStyle: { opacity: 0.5 },
          itemStyle: { color: '#e55381' }
        }
      ]
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [data, chartTitle, symbol]);

  if (error) return <div className="text-red-500">{error}</div>;
  if (!data) return <div>Loading...</div>;

  return <div ref={chartRef} style={{ width: '100%', height: '100%' }} />;
};

export default ExpectedMoveComponent;