import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProductDetails from './ProductDetailsComponent';

const ShopComponent = ({ apiEndpoint }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(apiEndpoint);
        setProducts(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load products');
        setLoading(false);
      }
    };

    fetchProducts();
  }, [apiEndpoint]);

  const formatPrice = (price) => {
    return (price / 100).toFixed(2);
  };

  const handleViewDetails = (productId) => {
    setSelectedProduct(productId);
  };

  const handleBackToShop = () => {
    setSelectedProduct(null);
  };

  if (selectedProduct) {
    return <ProductDetails 
      productId={selectedProduct}
      onBack={handleBackToShop}
    />;
  }

  if (loading) {
    return (
      <div className="container mx-auto px-4 pt-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {[...Array(8)].map((_, i) => (
            <div key={i} className="animate-pulse">
              <div className="bg-gray-200 h-56 rounded-lg"></div>
              <div className="space-y-3 p-4">
                <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                <div className="h-4 bg-gray-200 rounded w-1/4"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-64">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 pt-10">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {products.map((product) => (
          <div 
            key={product.id}
            className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 cursor-pointer"
          >
            <div className="relative h-56 overflow-hidden">
              <img
                src={product.image_url}
                alt={product.title}
                className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                onError={(e) => {
                    console.error(`Image failed to load: ${product.image_url}`);
                }}
              />
            </div>
            <div className="p-4 space-y-2">
              <h3 className="font-semibold text-lg truncate">
                {product.title}
              </h3>
              <p className="text-gray-600 text-sm line-clamp-3">
                {product.description}
              </p>
              <div className="flex justify-between items-center mt-4">
                <span className="text-lg font-bold">
                  ${formatPrice(product.price)}
                </span>
                <button 
                    onClick={() => handleViewDetails(product.id)}
                    className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors">
                  查看详情
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopComponent;
