// src/LoginComponent.js

import React, { useState } from 'react';
import { LogIn, User, Lock, Mail } from 'lucide-react';
import axios from 'axios';

const LoginComponent = ({ onLoginSuccess }) => {
  const [mode, setMode] = useState('login');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, { 
        email: formData.email, 
        password: formData.password 
      });

      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);
      onLoginSuccess(response.data.user);
    } catch (err) {
      setError(err.response?.data?.message || 'Login failed');
    } finally {
      setIsLoading(false);
    }
  };

  // New handler for password reset
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/request-password-reset`, {
        email: formData.email
      });

      setSuccess('密码重置申请成功，请注意查收Email中的重置密码链接。');
    } catch (err) {
      setError(err.response?.data?.message || '密码重置邮件发送失败。');
    } finally {
      setIsLoading(false);
    }
  };

  const renderLoginForm = () => (
    <form onSubmit={handleLogin} className="space-y-6">
      {error && <div className="text-red-500">{error}</div>}
      <div className="relative">
        <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Email"
          required
          className="w-full pl-10 pr-3 py-2 border rounded-lg"
        />
      </div>
      <div className="relative">
        <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          placeholder="Password"
          required
          className="w-full pl-10 pr-3 py-2 border rounded-lg"
        />
      </div>
      <button 
        type="submit" 
        disabled={isLoading}
        className="w-full py-2 px-4 bg-teal-600 text-white rounded-lg hover:bg-teal-700 flex items-center justify-center"
      >
        <LogIn className="mr-2" size={20} />
        {isLoading ? '请稍候...' : '登录'}
      </button>
      <div className="text-center">
        <button 
          type="button"
          onClick={() => setMode('resetPassword')}
          className="text-red-600 hover:underline"
        >
          忘记密码?
        </button>
      </div>
    </form>
  );

  // New password reset form
  const renderResetPasswordForm = () => (
    <form onSubmit={handleResetPassword} className="space-y-4">
      {error && <div className="text-red-500">{error}</div>}
      {success && <div className="text-green-500">{success}</div>}
      <div className="relative">
        <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Email地址"
          required
          className="w-full pl-10 pr-3 py-2 border rounded-lg"
        />
      </div>
      <button 
        type="submit" 
        disabled={isLoading}
        className="w-full py-2 px-4 bg-orange-600 text-white rounded-lg hover:bg-orange-700 flex items-center justify-center"
      >
        {isLoading ? 'Sending...' : '重置密码'}
      </button>
      <div className="text-center">
        <button 
          type="button"
          onClick={() => setMode('login')}
          className="text-green-600 hover:underline"
        >
          返回登录
        </button>
      </div>
    </form>
  );

  return (
    <div className="max-w-md mx-auto mt-5 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-center">
        {mode === 'login' && '登录'}
        {mode === 'resetPassword' && '重置密码'}
      </h2>
      
      {mode === 'login' && renderLoginForm()}
      {mode === 'resetPassword' && renderResetPasswordForm()}
    </div>
  );
};

export default LoginComponent;