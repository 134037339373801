import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useShoppingCart } from './ShoppingCartContext';

const ProductDetails = ({ productId, onBack }) => {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { dispatch } = useShoppingCart();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/shop/courses/${productId}`);
        setProduct(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load product details');
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  const formatPrice = (price) => {
    return (price / 100).toFixed(2);
  };

  const handleAddtoCart = () => {
    dispatch({ type: 'ADD_TO_CART', payload: product });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-pulse space-y-4">
          <div className="h-48 bg-gray-200 rounded"></div>
          <div className="space-y-3">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-64">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  if (!product) return null;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left column - Image */}
        <div className="relative">
          <img
            src={product.image_url}
            alt={product.title}
            className="w-full rounded-lg shadow-lg"
          />
        </div>

        {/* Right column - Details */}
        <div className="space-y-6">
          <h1 className="text-3xl font-bold">{product.title}</h1>
          
          <p className="text-gray-600">{product.short_description}</p>
          
          <div className="text-2xl font-bold">
            价格: ${formatPrice(product.price)}
          </div>

          <div className="prose max-w-none">
            {product.long_description}
          </div>

          <div className="border-t border-gray-200 pt-6 mt-6">
            <p className="text-gray-600">
              购买课程后，课程下载信息将会发送至您在付款时所使用的电子邮箱地址。<br />
              若成功付款后未收到来自"support@pptfinance.com"的课程详情，请及时联系客服。
            </p>
          </div>

          <div className="flex justify-between items-center pt-6">
            <button
              onClick={onBack}
              className="px-6 py-2 border border-gray-300 rounded-lg hover:bg-gray-100 transition-colors"
            >
              回到商店
            </button>
            <button
              onClick={handleAddtoCart}
              className="px-6 py-2 bg-orange-400 text-white rounded-lg hover:bg-orange-600 transition-colors"
            >
              加入购物车
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;