// src/Dashboard.js

import React, { useState, useEffect } from 'react';
import NavBar from './NavBar';
import EChartsComponent from './EChartsComponent';
import ExpectedMoveComponent from './ExpectedMoveComponent';
import GammaProfileComponent from './GammaProfileComponent';
import LiquidityComponent from './LiquidityComponent';
import ScannerComponent from './ScannerComponent';
import RegSHOComponent from './RegSHO';
import ShopComponent from './ShopComponent';
import UserAccountDetails from './AccountDetail';
import { ENDPOINTS } from './config/dashboardConfig'

const SkeletonLoader = () => (
  <div className="animate-pulse space-y-4">
    <div className="h-6 bg-gray-300 rounded w-3/4"></div>
    <div className="h-4 bg-gray-200 rounded w-1/2"></div>
    <div className="h-40 bg-gray-100 rounded"></div>
  </div>
);

const Dashboard = ({ selectedItem }) => {
  console.log("Dashboard PROPS on RENDER:", { selectedItem }); // <--- MOVE LOG HERE - logs on every render

  const [currentEndpoint, setCurrentEndpoint] = useState(null);

  useEffect(() => {
    console.log("useEffect in Dashboard is running. selectedItem:", selectedItem);
    const selectedEndpoints = ENDPOINTS[selectedItem];
    console.log("  selectedEndpoints (from ENDPOINTS[selectedItem]):", selectedEndpoints); // <-- ADD THIS LOG
    if (selectedEndpoints) {
      const firstEndpointKey = Object.keys(selectedEndpoints)[0];
      const endpoint = selectedEndpoints[firstEndpointKey];
      console.log("  firstEndpointKey:", firstEndpointKey); // <-- ADD THIS LOG
      console.log("  endpoint (from selectedEndpoints[firstEndpointKey]):", endpoint); // <-- ADD THIS LOG
      setCurrentEndpoint(selectedEndpoints[firstEndpointKey]);
      console.log("  setCurrentEndpoint called with:", endpoint); // <-- ADD THIS LOG
      } else {
        console.log("  No endpoints found for selectedItem:", selectedItem); // <-- ADD THIS LOG
      }
  }, [selectedItem]);

  const handleEndpointChange = (newEndpoint) => {
    setCurrentEndpoint(newEndpoint);
    if (selectedItem === 'Live Room') {
      openLiveRoomWindow();
    }
  };

  const openLiveRoomWindow = () => {
    const width = 800;
    const height = 450;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    const liveRoomUrl = 'https://www.youtube.com/@PPTFinance/live?autoplay=1'; // This will be a static HTML file
    
    window.open(
      liveRoomUrl,
      'LiveTradingRoom',
      `width=${width},height=${height},top=${top},left=${left}`
    );
  };

  if (!currentEndpoint && <SkeletonLoader />) {
    return <div>Loading...</div>;
  }

  const renderContent = () => {
    console.log("renderContent function called. selectedItem:", selectedItem); // <-- LOG at start
    switch (selectedItem) {
      case 'Cycle':
        console.log("  renderContent case: Cycle");
        return <EChartsComponent key={`cycle-${currentEndpoint.url}`} apiEndpoint={currentEndpoint.url} chartTitle={currentEndpoint.title} />;
      case 'Expected Move':
        return <ExpectedMoveComponent 
        key={`expected-move-${currentEndpoint.url}`} 
        apiEndpoint={currentEndpoint.url} 
        chartTitle={currentEndpoint.title}
        symbol={currentEndpoint.symbol} />;

      case 'Gamma Profile':
        return <GammaProfileComponent 
          key={`gamma-profile-${currentEndpoint.url}`} 
          apiEndpoint={currentEndpoint.url} 
          chartTitle={currentEndpoint.title} 
          symbol={currentEndpoint.symbol}
        />;

      case 'Liquidity':
      return <LiquidityComponent 
        key={`liquidity-${currentEndpoint.url}`} 
        apiEndpoint={currentEndpoint.url} 
        chartTitle={currentEndpoint.title} 
      />;

      case 'Scanner':
        return <ScannerComponent 
          key={`scanner-${currentEndpoint.url}`} 
          apiEndpoint={currentEndpoint.url} />;
      
      case 'Dark Pool':
        return <RegSHOComponent 
          key={`scanner-${currentEndpoint.url}`} 
          apiEndpoint={currentEndpoint.url} />;

      case 'Shop':
        return <ShopComponent
          key={`shop-${currentEndpoint.url}`}
          apiEndpoint={currentEndpoint.url} />;

      case 'Account':
        return <UserAccountDetails
          key={`account-${currentEndpoint.url}`}
          apiEndpoint={currentEndpoint.url} />;

      case 'Live Room':
        return (
          <div className="p-6 flex flex-col items-center justify-center"> {/* Container for styling */}
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              实时交易直播间
            </h2>
            <p className="text-gray-600 mb-4 text-center">
              诚邀共赴实时交易直播，洞悉市场脉搏，直击前沿策略。
            </p>
            <p className="text-gray-600 mb-4 text-center">
              直播时间：每交易日，美国太平洋西部时间，早上7:30-12:30
            </p>
            <button
              onClick={openLiveRoomWindow}
              className="bg-cyan-600 hover:bg-cyan-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              立即收看YouTube直播
            </button>
          </div>
        );;
    // Add cases for other chart types
      default:
        return <div>Select a chart type</div>;
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <NavBar 
        endpoints={ENDPOINTS[selectedItem]} 
        currentEndpoint={currentEndpoint} 
        onEndpointChange={handleEndpointChange} 
        className="fixed w-full z-10"
      />
      <div className="flex-grow">
        {renderContent()}
      </div>
    </div>
  );
};

export default Dashboard;