import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { User, ShoppingCart } from 'lucide-react';

const UserAccountDetails = () => {
    const [userDetails, setUserDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const token = localStorage.getItem('access_token');
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/account-details`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setUserDetails(response.data);
                setIsLoading(false);
            } catch (err) {
                setError(err.response?.data?.error || 'Please Login to manage account.');
                setIsLoading(false);
            }
        };

        fetchUserDetails();
    }, []);

    if (isLoading) {
        return (
            <div className="p-6 text-center">
                <p>Loading account details...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="p-6 bg-red-50 text-red-600">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div className="p-6 space-y-6">
            <div className="bg-white shadow rounded-lg p-6">
                <h2 className="text-2xl font-bold mb-4 flex items-center">
                    <User className="mr-2" size={24} />
                    账户信息
                </h2>
                <div className="grid md:grid-cols-2 gap-4">
                    <div>
                        <p className="font-medium text-gray-600">名字: {userDetails.firstName}</p>
                    </div>
                </div>
            </div>

            <div className="bg-white shadow rounded-lg p-6">
                <h2 className="text-2xl font-bold mb-4 flex items-center">
                    <ShoppingCart className="mr-2" size={24} />
                    订单详情
                </h2>
                {userDetails.purchaseHistory && userDetails.purchaseHistory.length > 0 ? (
                    <table className="w-full">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="p-2 text-left">购买日期</th>
                                <th className="p-2 text-left">课程</th>
                                <th className='p-2 text-left'>下载链接</th>
                                <th className="p-2 text-right">金额</th>
                            </tr>
                        </thead>
                        <tbody>
                        {[...userDetails.purchaseHistory]
                            .sort((a, b) => new Date(b.order_date) - new Date(a.order_date))
                            .map((purchase, index) => (
                                <tr key={index} className="border-b">
                                    <td className="p-2">{new Date(purchase.order_date).toLocaleDateString()}</td>
                                    <td className="p-2">
                                        {purchase.items.map((item, itemIndex) => (
                                            <div key={itemIndex}>
                                                {item.course_name} (x{item.quantity})
                                            </div>
                                        ))}
                                    </td>
                                    <td className='p-2'>
                                        {purchase.items.map((item, itemIndex) => (
                                            <div key={itemIndex}>
                                                <a href={item.bd_link} target="_blank" rel="noopener noreferrer" className="text-blue-700 hover:underline">百度</a>
                                                {" | "}
                                                <a href={item.gd_link} target="_blank" rel="noopener noreferrer" className="text-green-700 hover:underline">Google</a>
                                            </div>
                                        ))}
                                    </td>
                                    <td className="p-2 text-right">${(purchase.total_amount / 100).toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No purchase history found.</p>
                )}
            </div>
        </div>
    );
};

export default UserAccountDetails;