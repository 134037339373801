import { format } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';

export const processData = (chartData) => {
  return Object.keys(chartData.DateTime).map(key => ({
    time: chartData.DateTime[key], // Timestamp is in milliseconds already
    strength: parseFloat(chartData.Last[key]),
    direction: parseFloat(chartData.Direction[key]),
    energy: parseFloat(chartData.Energy[key]),
  })).filter(item => !isNaN(item.strength)).sort((a, b) => a.time - b.time);
};

export const createChartOption = (processedData, chartTitle) => {
  const xAxisData = processedData.map(item => {
    const date = new Date(item.time) - getTimezoneOffset('America/Los_Angeles');
    return format(date, 'yyyy/MM/dd HH:mm');
  });

  return {
    title: {
      text: chartTitle,
      left: "center",
      textStyle: {
        color: "#eeeeee",
      },
    },
    backgroundColor: "rgba(51,51,51,1)",
    textStyle: {
      color: "#eeeeee",
    },
    grid: [
      {
        left: 60,
        right: 50,
        top: '10%',
        height: "50%",
      },
      {
        left: 60,
        right: 50,
        top: "65%",
        height: "15%",
      },
    ],
    color: [
      "#dd6b66", "#759aa0", "#e69d87", "#8dc1a9", "#ea7e53",
      "#eedd78", "#73a373", "#73b9bc", "#7289ab", "#91ca8c", "#f49f42",
    ],
    legend: {
      data: ['Strength', 'Direction', 'Energy'],
      orient: "horizontal",
      left: 10,
      top: 0,
      textStyle: {
        color: "#eeeeee",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      formatter: function (params) {
        let tooltipText = `${params[0].axisValue}<br/>`;
        params.forEach(param => {
          tooltipText += `${param.seriesName}: ${param.value}<br/>`;
        });
        return tooltipText;
      },
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: "none",
        },
        restore: {},
        saveAsImage: {},
      },
    },
    axisPointer: {
      link: [{
        xAxisIndex: [0, 1]
      }]
    },
    dataZoom: [
      {
        type: 'slider',
        show: true,
        start: 95,
        end: 100,
        top: '85%',
        height: 30, // Increased height
        backgroundColor: "rgba(47,69,84,0.8)", // Slightly more opaque
        dataBackground: {
          lineStyle: { color: '#777' },
          areaStyle: { color: '#444' }
        },
        fillerColor: "rgba(167,183,204,0.4)",
        handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
        handleSize: '110%',
        handleStyle: {
          color: "#a7b7cc",
        },
        textStyle: {
          color: "#eeeeee",
        },
        borderColor: "#999",
        xAxisIndex: [0, 1],
      },
      {
        type: "inside",
        xAxisIndex: [0, 1],
      },
    ],
    xAxis: [
      {
        type: 'category',
        gridIndex: 0,
        data: xAxisData,
        axisLabel: {
          color: "#eeeeee",
        },
        axisLine: {
          show: true,
          onZero: false,
        },
        axisTick: {
          show: true,
        },
        splitArea: {
          interval: function (index) {
            const timestamp = xAxisData[index];
            const date = new Date(timestamp);
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return hours === '06' && minutes === '30'; // Returns true/false
          },
          show: true,
          areaStyle: {
            color: ['rgba(107, 59, 59, 0.15)', 'rgba(67, 88, 109, 0.15)']
          }

        }
      },
      {
        type: 'category',
        gridIndex: 1,
        data: xAxisData,
        axisLabel: {
          color: "#eeeeee",
        },
        splitArea: {
          interval: function (index) {
            const timestamp = xAxisData[index];
            const date = new Date(timestamp);
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return hours === '06' && minutes === '30'; // Returns true/false
          },
          show: true,
          areaStyle: {
            color: ['rgba(107, 59, 59, 0.15)', 'rgba(67, 88, 109, 0.15)']
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        gridIndex: 0,
        axisLabel: {
          color: "#eeeeee",
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#777'
          }
        }
      },
      {
        type: 'value',
        gridIndex: 1,
        axisLabel: {
          color: "#eeeeee",
        },
        min: -2500,
        max: 2500,
        splitNumber: 10,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#777'
          }
        }

      }
    ],
    series: [
      {
        name: 'Strength',
        data: processedData.map(item => item.strength),
        type: 'bar',
        smooth: true,
        xAxisIndex: 1,
        yAxisIndex: 1,
        lineStyle: { width: 2 },
        itemStyle: {
          color: (params) => params.value >= 0 ? '#91ca8c' : '#ea7e53'
        },
        label: {
          show: true,
          formatter: function (params) {
            if (params.dataIndex === processedData.length - 1) {
              return `{a|Strength: ${params.value}}`
            }
            return '';
          },
          position: 'top',
          color: '#fff',
          rich: {
            a: {
              backgroundColor: (params) => params.value >= 0 ? '#91ca8c' : '#ea7e53',
              borderWidth: 1,
              borderRadius: 4,
              padding: [4, 6]
            }
          },
        }
      },
      {
        name: 'Direction',
        data: processedData.map(item => item.direction),
        type: 'line',
        smooth: true,
        xAxisIndex: 0,
        yAxisIndex: 0,
        lineStyle: { width: 2 },
        itemStyle: {
          color: (params) => params.value >= 0 ? '#73b9bc' : '#e69d87'
        },
        areaStyle: {
          color: 'rgba(0,0,0,0.3)'
        },
        label: {
          show: true,
          formatter: function (params) {
            // 仅最后一个数据点显示标签
            if (params.dataIndex === processedData.length - 1) {
              // 根据数值正负选择样式
              const styleClass = params.value >= 0 ? 'positive' : 'negative';
              return `{${styleClass}|Direction: ${params.value}}`;
            }
            return '';
          },
          position: 'top',
          color: '#fff',
          rich: {
            // 定义正负值样式
            positive: {
              backgroundColor: '#73b9bc', // 正数背景色
              borderWidth: 1,
              borderRadius: 4,
              padding: [4, 6]
            },
            negative: {
              backgroundColor: '#e69d87', // 负数背景色
              borderWidth: 1,
              borderRadius: 4,
              padding: [4, 6]
            }
          }
        }
      },
      {
        name: 'Energy',
        data: processedData.map(item => item.energy),
        type: 'bar',
        xAxisIndex: 0,
        yAxisIndex: 0,
        itemStyle: {
          color: (params) => params.value >= 0 ? '#d8d78f' : '#aa5042'
        },
        label: {
          show: true,
          formatter: function (params) {
            // 仅最后一个数据点显示标签
            if (params.dataIndex === processedData.length - 1) {
              // 根据数值正负选择样式
              const styleClass = params.value >= 0 ? 'positive' : 'negative';
              return `{${styleClass}|Energy: ${params.value}}`;
            }
            return '';
          },
          position: 'top',
          color: '#fff',
          rich: {
            // 定义正负值样式
            positive: {
              backgroundColor: '#d8d78f', // 正数背景色
              borderWidth: 1,
              borderRadius: 4,
              padding: [4, 6]
            },
            negative: {
              backgroundColor: '#aa5042', // 负数背景色
              borderWidth: 1,
              borderRadius: 4,
              padding: [4, 6]
            }
          }
        }
      },
    ],
  };
};