import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import axios from 'axios';

const RegSHOComponent = ({ apiEndpoint, chartTitle }) => {
    const [option, setOption] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [inputSymbol, setInputSymbol] = useState('SPY');
    const [submittedSymbol, setSubmittedSymbol] = useState('SPY');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setError(null);

                const res = await axios({
                    method: 'post',
                    url: apiEndpoint,
                    data: { symbol: submittedSymbol },
                    headers: { 'Content-Type': 'application/json' }
                });

                if (res.data !== undefined) {
                    const objArray = res.data.map(item => ({
                        date: item.tradeReportDate,
                        reportingFacility: item.reportingFacilityCode,
                        shortExemptParQuantity: item.shortExemptParQuantity,
                        totalParQuantity: item.totalParQuantity,
                        shortParQuantity: item.shortParQuantity,
                        dailyShortSum: item.dailyShortSum,       // From backend
                        dailyTotalSum: item.dailyTotalSum,       // From backend
                        dailyShortRatio: item.dailyShortRatio    // From backend
                    }));

                    const newOption = {
                        backgroundColor: "rgba(51,51,51,1)",
                        title: {
                            text: chartTitle,
                            left: "center",
                            textStyle: {
                                color: "#eeeeee",
                            },
                            show: true
                        },
                        toolbox: 
                        {
                            feature:
                            {
                                saveAsImage:
                                {
                                    type: 'png',
                                    show: true
                                }
                            }
                        },
                        tooltip: {
                            trigger: "axis",
                        
                        },
                        axisPointer: {
                            type: "cross",
                            label: {
                                backgroundColor: "#6a7985",
                            },
                            link: [{
                                yAxisIndex: [0,1],
                            }]
                        },
                        grid: 
                        [
                            {
                                left: 50,
                                width: '45%',
                                containLabel: true,
                                show: true,
                                backgroundColor: 'rgba(230, 240, 255, 0.1)'
                            },
                            {
                                left: '50%',
                                width: '45%',
                                containLabel: true,
                                show: true,
                                backgroundColor: 'rgba(245, 245, 220, 0.15)'
                            }
                        ],
                        legend: {
                            // data: ['Total Par Qty', 'Short Par Qty', 'Short Exempt Qty'],
                            orient: "horizontal",
                            left: 10,
                            textStyle: {
                                color: "#eeeeee",
                            },
                        },
                        dataZoom: [{
                            type: "slider",
                            start: 50,
                            end: 100,
                            orient: 'vertical',
                            left: 5,
                            yAxisIndex:[0, 1]
                        }],
                        yAxis: 
                        [
                            {
                                type: "category",
                                dimension: 3
                            },
                            {
                                type: "category",
                                dimension: 3,
                                gridIndex: 1,
                                position: 'right',
                                
                            }
                    
                        ],
                        xAxis: [
                            {
                                type: "value",
                                axisLabel: {
                                    formatter: function (value) {
                                        return value/1000000 + 'M';
                                    }
                                }
                            },
                            {
                                type: "value",
                                gridIndex: 1,
                                inverse: true,
                                min: 0,
                                max: 1,
                            }
                        ],
                        series: [
                            {
                                name: "Total - NQTRF",
                                type: "bar",
                                stack: 'total',
                                emphasis: {
                                    focus: 'series'
                                },
                                datasetIndex: 1,
                                encode: {
                                    tooltip: ["totalParQuantity"],
                                    x: "totalParQuantity",
                                },
                            },
                            {
                                name: "Total - NCTRF",
                                type: "bar",
                                stack: 'total',
                                emphasis: {
                                    focus: 'series'
                                },
                                datasetIndex: 2,
                                encode: {
                                    tooltip: ["totalParQuantity"],
                                    x: "totalParQuantity",
                                },
                            },
                            {
                                name: "Total - NYTRF",
                                type: "bar",
                                stack: 'total',
                                emphasis: {
                                    focus: 'series'
                                },
                                datasetIndex: 3,
                                encode: {
                                    tooltip: ["totalParQuantity"],
                                    x: "totalParQuantity",
                                },
                            },
                            {
                                name: "Short - NQTRF",
                                type: "bar",
                                stack: "Short",
                                emphasis: {
                                    focus: 'series'
                                },
                                datasetIndex: 1,
                                encode: {
                                    tooltip: ["shortParQuantity"],
                                    x: "shortParQuantity",
                                },
                            },
                            {
                                name: "Short - NCTRF",
                                type: "bar",
                                stack: 'Short',
                                emphasis: {
                                    focus: 'series'
                                },
                                datasetIndex: 2,
                                encode: {
                                    tooltip: ["shortParQuantity"],
                                    x: "shortParQuantity",
                                },
                            },
                            {
                                name: "Short - NYTRF",
                                type: "bar",
                                stack: 'Short',
                                emphasis: {
                                    focus: 'series'
                                },
                                datasetIndex: 3,
                                encode: {
                                    tooltip: ["shortParQuantity"],
                                    x: "shortParQuantity",
                                },
                            },
                            {
                                name: "Exempt - NQTRF",
                                type: "bar",
                                stack: "Short",
                                emphasis: {
                                    focus: 'series'
                                },
                                datasetIndex: 1,
                                encode: {
                                    tooltip: ["shortExemptParQuantity"],
                                    x: "shortExemptParQuantity",
                                },
                            },
                            {
                                name: "Exempt - NCTRF",
                                type: "bar",
                                stack: 'Short',
                                emphasis: {
                                    focus: 'series'
                                },
                                datasetIndex: 2,
                                encode: {
                                    tooltip: ["shortExemptParQuantity"],
                                    x: "shortExemptParQuantity",
                                },
                            },
                            {
                                name: "Exempt - NYTRF",
                                type: "bar",
                                stack: 'Short',
                                emphasis: {
                                    focus: 'series'
                                },
                                datasetIndex: 3,
                                encode: {
                                    tooltip: ["shortExemptParQuantity"],
                                    x: "shortExemptParQuantity",
                                },
                            },
                            {
                                name: "DPI",
                                type: "bar",
                                datasetIndex: 1,
                                encode: {
                                    tooltip: ["dailyShortRatio"],
                                    x: "dailyShortRatio"
                                },
                                xAxisIndex: 1,
                                yAxisIndex: 1,
                                label:
                                    {
                                        show: true,
                                        formatter: '{@dailyShortRatio}',
                                        color: '#eeeeee'
                                    },
                                itemStyle: {
                                    color: '#759aa0'
                                },
                                markPoint: {
                                    data: [
                                        {
                                            type: 'max', 
                                            name: 'Max',
                                            itemStyle: {
                                                color: 'rgb(184,215,200)'
                                            }
                                        },
                                        {
                                            type: 'min', 
                                            name: 'Min',
                                            itemStyle: {
                                                color: 'rgb(205,123,118)'
                                            }
                                        }
                                    ],
                                },
                            },

                        ],
                        dataset: [
                            {
                                source: objArray,
                            },
                            {
                                transform: {
                                    type:'filter',
                                    config: { dimension: 'reportingFacility','=': 'NQTRF'}
                                }
                            },
                            {
                                transform: {
                                    type:'filter',
                                    config: { dimension: 'reportingFacility','=': 'NCTRF'}
                                }
                            },
                            {
                                transform: {
                                    type:'filter',
                                    config: { dimension: 'reportingFacility','=': 'NYTRF'}
                                }
                            }
                        ],
                    };
                    setOption(newOption);
                    setLoading(false);
                }
            } catch (e) {
                setError(`Failed to fetch data: ${e.message}`);
                setLoading(false);
            }
        };

        fetchData();
    }, [apiEndpoint, chartTitle, submittedSymbol]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmittedSymbol(inputSymbol.trim().toUpperCase());
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="h-full flex flex-col">
            <form onSubmit={handleSubmit} className="p-1 bg-gray-800 justify-center flex gap-2">
                <input
                    type="text"
                    value={inputSymbol}
                    onChange={(e) => setInputSymbol(e.target.value)}
                    placeholder="Enter stock symbol"
                    className="px-2 py-1 rounded bg-gray-600 text-white"
                />
                <button
                    type="submit"
                    className="px-2 py-1 bg-yellow-500 rounded hover:bg-blue-700 transition-colors"
                >
                    Load Data
                </button>
            </form>
            
            {loading ? (
                <div className="flex-1 grid text-sm w-32 items-center">Loading...</div>
            ) : error ? (
                <div className="flex-1 grid text-sm w-32 items-center text-red-500">Error: {error}</div>
            ) : (
                <ReactECharts
                    option={option}
                    style={{ height: '100%', width: '100%' }}
                    opts={{ renderer: 'svg' }}
                />
            )}
        </div>
    );
};

export default RegSHOComponent;