import React, { useState } from 'react';
import { useShoppingCart } from './ShoppingCartContext';
import { ShoppingCart as ShoppingCartIcon, X as CloseIcon, Trash2 } from 'lucide-react';
import CustomerInfoForm from './CustomerInfoForm';

function ShoppingCart() {
  const { state, dispatch } = useShoppingCart();
  const [cartOpen, setCartOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);

  const toggleCart = () => {
    setCartOpen(!cartOpen);
  };

  const closeCart = () => {
    setCartOpen(false);
  };

  const formatPrice = (price) => {
    return (price / 100).toFixed(2);
  };

  const handleCheckout = () => {
    setFormOpen(true);  // Open the form modal on checkout
  };

  const handleFormSubmit = (customerInfo) => {
    setFormOpen(false);  // Close form after submission
    // Proceed with Stripe checkout logic here
  };

  const handleRemoveItem = (itemId) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { id: itemId } });
  };

  // Ensure that state and state.cart are defined before accessing the cart
  const cartItems = state && state.cart ? state.cart : [];

  const subtotal = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);

  // Calculate the total number of items in the cart (sum of quantities)
  const totalItems = cartItems.reduce((acc, item) => acc + item.quantity, 0);

  if (totalItems === 0) {
    return null;
  }

  return (
    <div className="relative">
      <button onClick={toggleCart} className="text-white fixed top-20 right-20 z-20 bg-black bg-opacity-30 backdrop-blur-md p-3 rounded-full shadow-lg">
        <ShoppingCartIcon className="h-8 w-8" />
        {totalItems > 0 && (
          <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs w-5 h-5 rounded-full flex justify-center items-center">
            {totalItems}
          </span>
        )}
      </button>

      {cartOpen && (
        <div className="fixed right-20 top-20 w-64 bg-white shadow-lg rounded-lg">
          {/* Close "X" button */}
          <button
            onClick={closeCart}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            <CloseIcon className="h-5 w-5" />
          </button>
          <div className="p-4 border-b">
            <h3 className="font-semibold">购物车</h3>
          </div>
          <div className="p-4">
            {cartItems.length === 0 ? (
              <p className="text-gray-500">Your cart is empty.</p>
            ) : (
              <ul>
                {cartItems.map((item) => (
                  <li key={item.id} className="flex justify-between mb-2">
                    <span>{item.title}</span>
                    <span className="text-gray-700">x{item.quantity}</span>
                    <span className="text-gray-700">${formatPrice(item.price)}</span>
                    {/* Delete Button */}
                    <button
                      onClick={() => handleRemoveItem(item.id)}
                      className="text-red-500 hover:text-red-700 ml-2"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </li>
                ))}
              </ul>
            )}
            {/* Checkout Button */}
            {cartItems.length > 0 && (
              <button 
                onClick={handleCheckout}
                className="w-full mt-4 bg-green-500 text-white py-2 rounded-md hover:bg-green-600 transition-colors">
                下单
              </button>
            )}
          </div>
        </div>
      )}
      {/* Display Customer Info Form as a modal */}
      {formOpen && (
        <CustomerInfoForm
          onSubmit={handleFormSubmit}
          onClose={() => setFormOpen(false)}
          cartItems={cartItems}
          subtotal={subtotal}
        />
      )}
    </div>
  );
}

export default ShoppingCart;
