import React from 'react';
import ShoppingCart from './ShoppingCart';  // Import the ShoppingCart component

const NavBar = ({ endpoints, currentEndpoint, onEndpointChange }) => {
  return (
    <nav className="bg-gray-800 p-4 w-full flex justify-between z-10">
      {/* Navigation buttons */}
      <ul className="flex space-x-4">
        {Object.entries(endpoints).map(([key, value]) => (
          <li key={key}>
            <button
              className={`px-3 py-2 rounded-md text-sm font-medium ${
                currentEndpoint.url === value.url
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white'
              }`}
              onClick={() => onEndpointChange(value)}
            >
              {key}
            </button>
          </li>
        ))}
      </ul>
        <ShoppingCart />
    </nav>
  );
};

export default NavBar;
