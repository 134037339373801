import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as echarts from 'echarts';
import axios from 'axios';
import { processData, createChartOption } from './cycleChartConfig';

const REFRESH_INTERVAL = 30000; // 30 seconds in milliseconds

const EChartsComponent = ({ apiEndpoint, chartTitle }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const [chartData, setChartData] = useState(null);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    if (!apiEndpoint) {
      setError('API endpoint is not configured. Please check your environment variables.');
      return;
    }

    try {
      const response = await axios.get(apiEndpoint);
      if (response.data && Object.keys(response.data).length > 0) {
        setChartData(response.data);
      } else {
        console.error('API response is empty or invalid');
        setError('Received invalid data from API');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    }
  }, [apiEndpoint]);

  useEffect(() => {
    fetchData(); // Initial data fetch

    const intervalId = setInterval(fetchData, REFRESH_INTERVAL);

    // Clean up the interval on component unmount or when apiEndpoint changes
    return () => clearInterval(intervalId);
  }, [fetchData, apiEndpoint]);

  useEffect(() => {
    if (!chartRef.current || !chartData) {
      return;
    }

    const updateChart = () => {
      if (!chartInstanceRef.current) {
        chartInstanceRef.current = echarts.init(chartRef.current);
      }

      const processedData = processData(chartData);

      if (processedData.length === 0) {
        console.error('No valid data points found');
        setError('No valid data points found.');
        return;
      }

      const option = createChartOption(processedData, chartTitle);
      chartInstanceRef.current.setOption(option, true);
    };

    updateChart();

    const handleResize = () => {
      chartInstanceRef.current && chartInstanceRef.current.resize();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [chartData, chartTitle]);

  useEffect(() => {
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.dispose();
      }
    };
  }, []);

  if (error) {
    return <div className="w-full h-full flex items-center justify-center text-red-500">{error}</div>;
  }

  return (
    <div 
      ref={chartRef} 
      className="w-full h-full"
      style={{ 
        background: 'rgba(51,51,51,1)'
      }}
    >
      {!chartData && <div className="w-full h-full flex items-center justify-center">Loading...</div>}
    </div>
  );
};

export default EChartsComponent;